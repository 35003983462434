class PskGallery {
  static #galleryClass = `psk-gallery`;
  static #closeButtonClass = `${PskGallery.#galleryClass}-close`;
  static #isTouch = 'ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch);
  static #defaultOpts = {
    initialSlide: 0,
    onOpen: () => {},
    onClose: () => {},
  };

  static #getBreakpoint() {
    if (window.matchMedia('(min-width: 1024px)').matches) return 'Large';
    if (window.matchMedia('(min-width: 768px)').matches) return 'Medium';
    return 'Small';
  }

  static #initSwiper(swiperContainer, opts) {
    const slides = swiperContainer.querySelectorAll('.swiper-slide');
    const videos = swiperContainer.querySelectorAll('video');
    const videoOpeningSlide = slides[opts.initialSlide]?.querySelector('video');
    const swiper = new Swiper(swiperContainer, {
      initialSlide: opts.initialSlide,
      loop: slides.length > 1,
      keyboard: true,
      preloadImages: false,
      threshold: 10,
      effect: PskGallery.#isTouch ? 'slide' : 'fade',
      fadeEffect: {
        crossFade: true,
      },
      lazy: {
        enabled: true,
        loadPrevNext: true,
      },
      pagination: {
        el: swiperContainer.querySelector('.swiper-pagination'),
        clickable: true,
      },
      navigation: {
        nextEl: swiperContainer.querySelector('.swiper-button-next'),
        prevEl: swiperContainer.querySelector('.swiper-button-prev'),
      },
    });

    swiper.on('slideChange', () => {
      videos.forEach(v => {
        if (v.getAttribute('controls') !== null) v.pause();
      });
    });

    if (videoOpeningSlide?.readyState === videoOpeningSlide?.HAVE_ENOUGH_DATA) {
      videoOpeningSlide?.play();
    } else {
      videoOpeningSlide?.addEventListener('canplay', () => videoOpeningSlide?.play(), { once: true });
    }
  }

  static #fetchJson(url) {
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    };

    return fetch(url, options)
      .then(res => res.json())
      .catch(console.error);
  }

  static #buildImage(obj) {
    return `
      <picture>
        <img alt="" data-src="${obj.image}" class="swiper-lazy">
      </picture>
      <div class="swiper-lazy-preloader"></div>`;
  }

  static #buildVideo(obj) {
    return `
      <div class="video">
        <video poster="${obj.image || ''}" preload="metadata" controls>
          <source src="${obj.video}" type="${obj.mimeType || 'video/mp4'}">
        </video>
      </div>`;
  }

  static #buildCaption(obj) {
    return `
      <div class="swiper-caption">
        <div>
          <div>${obj.caption}</div>
        </div>
      </div>`;
  }

  static #buildSlide(obj) {
    const captionClass = obj.caption ? '' : 'no-swiper-caption';
    const captionHtml = obj.caption ? PskGallery.#buildCaption(obj) : '';

    return `<div class="swiper-slide ${captionClass}">${obj.video ? PskGallery.#buildVideo(obj) : PskGallery.#buildImage(obj)}${captionHtml}</div>`;
  }

  static #createGalleryEl(html) {
    const el = document.createElement('div');

    el.classList.add(PskGallery.#galleryClass);
    el.innerHTML = html;

    return el;
  }

  static #handleKeyDown(e) {
    if (e.key === 'Escape') PskGallery.close();
  }

  static #handleTransitionEnd(opts) {
    return e => {
      const { currentTarget: gallery } = e;

      if (e.target !== gallery || e.propertyName !== 'opacity') return;

      const isOpen = gallery.classList.contains('open');

      if (isOpen) {
        PskGallery.#initSwiper(gallery.querySelector('.swiper-container'), opts);
        gallery.querySelector(`.${PskGallery.#closeButtonClass}`)?.focus();
        opts.onOpen(e);
      } else {
        gallery.remove();
        document.body.classList.remove('overflow-hidden');
        document.removeEventListener('keydown', PskGallery.#handleKeyDown);
        opts.onClose(e);
      }
    };
  }

  static #build(json, opts) {
    const slidesHtml = Object.values(json).map(PskGallery.#buildSlide).join('');
    const containers = 3;
    const galleryHtml = `
      ${'<div>'.repeat(containers)}
        <a href="javascript:;" class="${PskGallery.#closeButtonClass}">Close</a>
        <div class="${PskGallery.#galleryClass}-container">
          <div>
            <div class="swiper-container">
              <div class="swiper-wrapper">${slidesHtml}</div>
              <div class="swiper-pagination"></div>
              <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>
            </div>
          </div>
        </div>
      ${'</div>'.repeat(containers)}`;

    const gallery = PskGallery.#createGalleryEl(galleryHtml);
    const closeButton = gallery.querySelector(`.${PskGallery.#closeButtonClass}`);

    gallery.addEventListener('transitionend', PskGallery.#handleTransitionEnd(opts));
    closeButton.addEventListener('click', PskGallery.close);
    document.addEventListener('keydown', PskGallery.#handleKeyDown);

    document.body.insertAdjacentElement('beforeend', gallery);

    // Use 2 rAF to ensure the content is called on the next frame
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        gallery.classList.add('open');
        document.body.classList.add('overflow-hidden');
      });
    });
  }

  /**
   * @callback TransitionFn
   * @param {TransitionEvent} event
   */

  /**
   * Initializes the gallery and opens it.
   * @param {string} url - Url pointing to the JSON API
   * @param {object} [options] - A configuration object
   * @param {number} [options.initialSlide] - The index of the slide that should be opened first
   * @param {TransitionFn} [options.onOpen] - Function called when the gallery is open. A `TransitionEvent` will be passed as the first parameter
   * @param {TransitionFn} [options.onClose] - Function called when the gallery is closed. A `TransitionEvent` will be passed as the first parameter
   */
  static init(url, options = {}) {
    const opts = { ...PskGallery.#defaultOpts, ...options };
    const searchSeparator = url.includes('?') ? '&' : '?';

    document.activeElement?.blur();
    PskGallery.#fetchJson(`${url}${searchSeparator}breakpoint=${PskGallery.#getBreakpoint()}`).then(json => PskGallery.#build(json, opts));
  }

  /**
   * Closes the gallery if it's open
   */
  static close() {
    document.querySelector(`.${PskGallery.#galleryClass}`).classList.remove('open');
  }
}
